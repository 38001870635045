/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css?family=Mukta:700");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Source+Sans+Pro&display=swap");
/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

:root {
  --color-primary: #f2e3c4; //#f2dbae; //f2dbaeb8; //#f2dbaeb8
  --color-primary-text: rgba(0, 0, 0, 0.7); //#f2dbaeb8
  --color-primary-light: #f2dbae77; //#f2dbaeb8
  --background-color: var(--color-primary);
  --color-secondary: #155764;
  --color-secondary-light: #1557648e;
  --color-footer: #155764;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /*font-family: 'Open Sans', sans-serif;*/
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

p,
span,
a {
  /*font-family: 'Roboto', sans-serif;*/
  font-family: "Source Sans Pro", sans-serif;
}

h1 {
  font-size: 45px;
}

app-loader3 {
  display: flex;
  justify-content: center;
  align-items: center;
  .dribbble {
    position: fixed;
    display: block;
    right: 20px;
    bottom: 20px;
    img {
      display: block;
      height: 28px;
    }
  }
}

.pageTitle {
  margin: 0rem 0 1.5rem 0;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
}

.subtitle {
  text-align: center;
  margin: 0 1rem 3rem 1rem;
  font-size: 20px;
}

.loader {
  margin-top: 2rem;
}

.triangleBackgr1 {
  width: 100%;
  height: 150px;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    var(--background-color) 50%,
    var(--background-color) 100%
  );
}

.triangleBackgr2 {
  width: 100%;
  height: 150px;
  background: linear-gradient(
    to top right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    var(--background-color) 50%,
    var(--background-color) 100%
  );
}

.triangleFooter {
  width: 100%;
  height: 150px;
  background: linear-gradient(
    to bottom right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 50%,
    var(--color-footer) 50%,
    var(--color-footer) 100%
  );
}

@media screen and (max-height: 450px) {
  .triangleBackgr1 {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 50%,
      var(--background-color) 50%,
      var(--background-color) 100%
    );
  }
  
  .triangleBackgr2 {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      to top right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 50%,
      var(--background-color) 50%,
      var(--background-color) 100%
    );
  }
  
  .triangleFooter {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      to bottom right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 50%,
      var(--color-footer) 50%,
      var(--color-footer) 100%
    );
  }
}

.titleColor1 {
  color: var(--color-secondary);
}

.titleColor2 {
  color: var(--color-primary-text);
}

.titleUnderline {
  background-color: var(--color-primary);
  width: 70px;
  height: 2px;
  border-radius: 2px;
  margin: 2px 0 1rem 0;
}

.backgrColor1 {
  background-color: var(--background-color);
}

.blockSpaceTop {
  padding-top: 5rem;
}

.blockSmallSpaceTop {
  padding-top: 2rem;
}

.navPlaceholder {
  height: 3.5rem;
}

.fe-input {
  border: 1px solid var(--color-primary);
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 16px;
  border-radius: 2px;
  width: 100%;
  text-transform: uppercase;
  margin-top: 1rem;
}

.fe-input:not(:placeholder-shown) {
  text-transform: unset;
}

.fe-button {
  border: 1px solid var(--color-primary);
  padding: 0.6rem 1rem 0.5rem 1rem;
  font-size: 16px;
  border-radius: 2px;
  width: 100%;
  text-transform: uppercase;
  margin-top: 1rem;
  background-color: white;
  color: rgba(0, 0, 0, 0.575);
  transition: 0.2s;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.fe-button:hover {
  background-color: var(--color-primary-light);
  border: 1px solid var(--color-primary);
}

.fe-button:active {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.fe-button2 {
  border: 1px solid var(--color-secondary);
  padding: 0.6rem 1rem 0.5rem 1rem;
  font-size: 16px;
  border-radius: 2px;
  width: 100%;
  text-transform: uppercase;
  margin-top: 1rem;
  background-color: white;
  color: var(--color-secondary);
  transition: 0.2s;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.fe-button2 a{
  text-decoration: none;
  color: var(--color-secondary);
}

.fe-button2 a:hover {
  text-decoration: none;
  color: var(--color-secondary);
  color: white !important;
}

.fe-button2:hover {
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
  color: white !important;
}

.fe-button2:active {
  background-color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}
